<template>
  <div class="resetPassword">
    <TopTabbar class="TopTabbar" title="重置密码" text="返回" :arrow="true"/>
    <div class="center">
      <van-form @submit="Save">
        <van-cell-group inset>
          <van-field
            v-model="userInfo.mobile"
            name="手机号"
            label="手机号"
            placeholder="请输入手机号"
            label-width="50px"
            :rules="[{ pattern:phoneRegular, message: '请输入正确的手机号' }]"
          />
          <van-field 
            v-model="userInfo.newpassword" 
            label="新密码" 
            type="password"
            placeholder="请输入新密码" 
            label-width="50px"
            :rules="[{ required:true, message:'新密码不能为空' }]"
          />
          <van-field
            v-model="userInfo.captcha"
            center
            clearable
            label="验证码"
            placeholder="请输入短信验证码"
            label-width="50px"
            :rules="[{ required:true, message:'验证码不能为空' }]"
          >
            <template #button>
              <van-button size="small" type="primary" @click="sendCode" :disabled="codeButton">{{ codeValue }}</van-button>
            </template>
          </van-field>
        </van-cell-group>
        <van-button class="save" round block type="primary" native-type="submit">
          重置
        </van-button>
      </van-form>
    </div>
  </div>
</template>

<script setup>
  import { useRouter } from 'vue-router'
  import { ref,reactive } from 'vue'
  import { showToast } from 'vant'
  import { reqGetCode } from '../../../API/login'
  import { reqResetPwd } from '../../../API/user'

  const $router = useRouter()
  const userInfo = reactive({
    mobile:'',
    newpassword:'',
    captcha:''
  })

  //验证码按钮禁止
  let codeButton = ref(false)
  let codeValue = ref('发送验证码')

  //手机号验证正则
  const phoneRegular = ref(/^1[3456789]\d{9}$/)

  //发送验证码
  const sendCode = async () => {
    const result = await reqGetCode({mobile:userInfo.mobile,event:'resetpwd'})
    if(result.code != 1) return false
    showToast('发送成功')
    codeButton.value = true
    let count = ref(60)
    const timer = setInterval(() => {
      count.value--
      codeValue.value = count.value + '重新发送'
      if(count.value <= 0){
      codeButton.value = false
      codeValue.value = '发送验证码'
      clearInterval(timer)
    }
    },1000)
  }

  //保存
  const Save = async () => {
    const result = await reqResetPwd(userInfo)
    if(result.code == 1) $router.go(-1)
    showToast(result.msg)
    console.log(result);
  }
</script>

<style lang="scss" scoped>
  .resetPassword{
    width: 100%;
    height: 100vh;
    .center{
      width: 100%;
      height: calc(100% - 46px);
      background-color: #edfafa;
      .van-cell-group{
        margin: 0;
        border-radius: 0;
      }
      .save{
        width: 70%;
        margin: 15px auto 0 auto;
      }
    }
  }
  ::v-deep(.van-field){
    width: 100%;
    margin: 0;
    font-size: 16px;
    height: 60px;
  }
  ::v-deep(.van-image__img){
    border-radius: 50%;
    overflow: hidden;
  }
</style>